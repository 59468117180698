import store from '@/state/store'
import AccessLevels from '../components/roles/access-levels'

export default [{
        path: '*',
        name: '404',
        meta: {
            authRequired: true,
        },
        component: () =>
            import ('../views/pages/utility/error-404')
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
    },

    {
        path: '/unauthorized',
        name: 'Access Denied',
        component: () =>
            import ('../views/pages/account/unauthorised'),
        meta: { 
            authRequired: false,
        },
    },

    {
        path: '/password-reset',
        name: 'Password Reset',
        component: () =>
            import ('../views/pages/account/password-reset'),
            meta: {
                beforeResolve(routeTo, routeFrom, next) {
                    if (store.getters['auth/loggedIn']) {
                        next({ name: 'home' })
                    } else {
                        next()
                    }
                },
            },
    },

    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () =>
            import ('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
    },

    // Activate Account
    {   path: '/verify/:token',
        name: 'verify',
        component: () =>
            import ('../views/pages/account/activateCustomer.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (!store.getters['auth/loggedIn']) {
                    next()
                }
            },
        }

    },

    {
        path: '/verify-agent/:token',
        name: 'verify',
        component: () =>
            import ('../views/pages/account/activate'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                if (!store.getters['auth/loggedIn']) {
                    next()
                }
            },
        }

    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                store.dispatch('authservice/logout')

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                        (route) => route.push('/login')
                    )
                    // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : {...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
            meta: { requiresAuth: true, roles: AccessLevels.ALL_STAFF }
        },
        component: () =>
            import ('../views/pages/dashboard/index')
    }
    ,
    {
        path: '/services-dashboard',
        name: 'home',
        meta: {
            authRequired: true,
            meta: { requiresAuth: true, roles: AccessLevels.HQ_STAFF_ONLY }
        },
        component: () =>
            import ('../views/pages/services-dashboard/index')
    },

    {
        path: '/teller-dashboard',
        name: 'home',
        meta: {
            authRequired: true,
            meta: { requiresAuth: true, roles: AccessLevels.ALLOW_ALL }
        },
        component: () =>
            import ('../views/pages/dashboard/teller-dashboard/index')
    },

    //REMITTANCES
    {
        path: '/remittances',
        name: 'Wiremit Remittances',
        meta: { authRequired: true ,
            meta: { requiresAuth: true, roles: AccessLevels.BRANCH_STAFF_ONLY }

        },
        component: () =>
            import ('../views/pages/remittances/remittances')
    },

    {
        path: '/remittances/reversals',
        name: 'Transaction Reversal',
        meta: { authRequired: true ,
            meta: { requiresAuth: true, roles: AccessLevels.AGENT_STAFF_ONLY }

        },
        component: () =>
            import ('../views/pages/remittances/authoriseReversal')
    },
 

    //SHOP MANAGEMENT
    {
        path: '/shops',
        name: 'Agent Shops',
        meta: { authRequired: true,
            meta: { requiresAuth: true, roles:  AccessLevels.AGENT_STAFF_ONLY }
         },
        component: () =>
            import ('../views/pages/shops/shops')
    },


    //ACCOUNT MANAGEMENT
    {
        path: '/accounts',
        name: 'Wiremit Accounts',
        meta: { authRequired: true,roles:  AccessLevels.AGENT_STAFF_ONLY },
        component: () =>
            import ('../views/pages/account-management/accounts')
    },
    {
        path: '/funds-transfer',
        name: 'Funds Transfer',
        meta: { authRequired: true ,roles:  AccessLevels.ALL_ADMINS_ONLY},
        component: () =>
            import ('../views/pages/account-management/fundsTransfer')
    },

    //ADVERTISEMENT MANAGEMENT
    {
        path: '/advertisements',
        name: 'Manage Advertisements',
        meta: { authRequired: true, roles: AccessLevels.HQ_STAFF_ONLY },
        component: () =>
            import ('../views/pages/advertisements/manage-advertisements')
    },

    //Vault Management
    {
        path: '/branch/branch-funding',
        name: 'branchfunding',
        meta: { authRequired: true,roles:  AccessLevels.ALL_ADMINS_ONLY },
        component: () =>
            import ('../views/pages/vault/branch-vault-funding')
    },
    {
        path: '/branch/close-branch-vault',
        name: 'closevault',
        meta: { authRequired: true,roles:  AccessLevels.ALL_ADMINS_ONLY  },
        component: () =>
            import ('../views/pages/vault/close-branch-vault')
    },
    {
        path: '/till/till-funding',
        name: 'tillfunding',
        meta: { authRequired: true ,roles:  AccessLevels.AGENT_STAFF_ONLY  },
        component: () =>
            import ('../views/pages/vault/till-funding')
    },

    {
        path: '/till/teller-till',
        name: 'closetill',
        meta: { authRequired: true ,roles:  AccessLevels.BRANCH_STAFF_ONLY },
        component: () =>
            import ('../views/pages/vault/teller-till')
    },

    {
        path: '/till/authorisation',
        name: 'authorise till closure',
        meta: { authRequired: true,roles:  AccessLevels.BRANCH_STAFF_ONLY  },
        component: () =>
            import ('../views/pages/vault/authoriseClosure')
    },


    //USER MANAGEMENT
    {
        path: '/users/registration',
        name: 'User Registration',
        meta: { authRequired: true , roles:  AccessLevels.ALL_ADMINS_ONLY  },
        component: () =>
            import ('../views/pages/user-management/registration')
    },
    {
        path: '/users',
        name: 'View Users',
        meta: { authRequired: true,roles:  AccessLevels.ALL_ADMINS_ONLY  },
        component: () =>
            import ('../views/pages/user-management/userlist')
    },

    // REPORTS
    {
        path: '/reports/remittances',
        name: 'Remittances Report',
        meta: { authRequired: true ,roles:  AccessLevels.ALL_ADMINS_ONLY  },
        component: () =>
            import ('../views/pages/reports/remittanceReports')
    },
    {
        path: '/reports/account-balances',
        name: 'Account Balances Report',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/reports/accountBalancesReport')
    },
    {
        path: '/reports/customers',
        name: 'Customer Report',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/reports/customerReports')
    },
    {
        path: '/reports/userAudit',
        name: 'User Audit Report',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/reports/userAudit/userAuditReport')
    },

    // System Configuration
    {
        path: '/business-conditions',
        name: 'Configuration',
        meta: { authRequired: true ,roles:  AccessLevels.HQ_STAFF_ONLY  },
        component: () =>
            import ('../views/pages/system-configurations/businessConditionsConfig')
    },

    {
        path: '/alerts',
        name: 'Configuration',
        meta: { authRequired: true,roles:  AccessLevels.HQ_STAFF_ONLY  },
        component: () =>
            import ('../views/pages/system-configurations/alertConfigs')
    },

    {
        path: '/roles',
        name: 'Role Configs',
        meta: { authRequired: true,roles:  AccessLevels.ALL_ADMINS_ONLY  },
        component: () =>
            import ('../views/pages/system-configurations/roleConfig')
    },

    {
        path: '/promotions',
        name: 'Configure Promotions',
        meta: { authRequired: true,roles:  AccessLevels.HQ_STAFF_ONLY  },
        component: () =>
            import ('../views/pages/promotions/manage-promos')
    },
    

        //ADMIN FUNCTIONS
        {
            path: '/admin/home',
            name: 'Home',
            meta: { authRequired: true ,roles:  AccessLevels.ALL_ADMINS_ONLY },
            component: () =>
                import ('../views/pages/super-admin/home/index')
        },
        {
            path: '/admin/manage-organizations',
            name: 'Manage Organizations',
            meta: { authRequired: true ,roles:  AccessLevels.ALL_ADMINS_ONLY },
            component: () =>
                import ('../views/pages/super-admin/manage-organizations/organizations')
        },
        {
            path: '/admin/detailed-single-organization/:organizationID',
            name: 'Expand Single Organization',
            meta: { authRequired: true ,roles:  AccessLevels.ALL_ADMINS_ONLY  },
            component: () =>
                import ('../views/pages/super-admin/manage-organizations/single-organizations')
        },
        {
            path: '/admin/org/users',
            name: 'Manage organization users',
            meta: { authRequired: true ,roles:  AccessLevels.ALL_ADMINS_ONLY },
            component: () =>
                import ('../views/pages/super-admin/manage-organizations/org-users')
        },


    //TEMPLATE
    {
        path: '/pages/faqs',
        name: 'Faqs',
        meta: { authRequired: false },
        component: () =>
            import ('../views/pages/utility/faqs')
    },

    {
        path: '/pages/error-404',
        name: 'Error-404',
        meta: { authRequired: false },
        component: () =>
            import ('../views/pages/utility/error-404')
    },
    {
        path: '/pages/error-500',
        name: 'Error-500',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/utility/error-500')
    },


    {
        path: '/charts/apex',
        name: 'Apex chart',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/charts/apex')
    },
    {
        path: '/charts/chartjs',
        name: 'Chartjs chart',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/charts/chartjs/index')
    },
    {
        path: '/charts/chartist',
        name: 'Chartist chart',
        meta: { authRequired: true },
        component: () =>
            import ('../views/pages/charts/chartist')
    },
]