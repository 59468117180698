// Define role levels and their associated roles
const AccessLevels = {
    SUPER_ADMINS_ONLY: ['superAdminDefault'],
    HQ_STAFF_ONLY: ['superAdminDefault','hq-staff'],
    ALL_ADMINS_ONLY: ['superAdminDefault', 'org-admin'],
    AGENT_STAFF_ONLY: ['superAdminDefault', 'org-admin', 'org-staff'],
    ALL_STAFF: ['superAdminDefault', 'org-admin', 'org-staff','branch-staff'],
    BRANCH_STAFF_ONLY: ['branch-staff'],
    ALLOW_ALL: ['superAdminDefault', 'org-admin', 'org-staff','branch-staff'],
  };
  
  // Export the role levels object
  export default AccessLevels;